/******************/
/******************/
/******************/
/** FIRST */
/******************/
/******************/
/******************/
/******************/
@import "./breakpoints.scss";

body {
  @include for-big-desktop-up {
    background-color: #dcf2ee;
  }

  & > div {
    background-color: white;

    @include for-big-desktop-up {
      width: 1800px;
      margin: 0 auto;
    }
  }
}

.main-page-container {
  position: relative;

  background-repeat: no-repeat;

  @include for-tablet-landscape-up {
    background-image: url("https://i.ibb.co/3k5RGLP/mainbgmall.png"),
      url("https://i.ibb.co/Cz0ZQRP/ludki-left-small.png"),
      url("https://i.ibb.co/3dBb0jv/ludki-prawo-small.png");
    background-position: left top, left bottom, right bottom;

    height: 100vh;
    min-height: 50vw;

  }
  @include for-desktop-up {
    background-image: url("https://i.ibb.co/3k5RGLP/mainbgmall.png"),
      url("https://i.ibb.co/nrpjQjq/ludki-left.png"),
      url("https://i.ibb.co/SXVSQ35/ludki-prawo.png");
    background-position: left top, left bottom, right bottom;
    background-repeat: no-repeat;
  }

  .phone-cloud {
    @include for-tablet-landscape-up {
      display: none;
    }
  }
  .content-container {
    width: 100%;
    float: right;

    @include for-tablet-landscape-up{
    margin-top: 40px;
    }

    .meeting-info-box {
      display: grid;
      grid-template-columns: 7fr 6fr;
      grid-template-rows: 2fr 1fr 1fr 0.5fr 8fr;
      grid-template-areas: 
      "title-big diagonal"
      "title-small diagonal"
      "subtitle  subtitle"
      "subtitle2  subtitle2"
      "rest rest";
      // background-image: url(https://i.ibb.co/HB9kVSg/tlojesien.png);

      @include for-tablet-landscape-up {
        display: grid;
        float: right;
        margin: 50px auto 0;
        
        background-color: rgba($color: #ffffff, $alpha: 0.85);
        border-radius: 8px;
        width: 800px;
      }
      @include for-desktop-up {
        margin-right: 210px;
        margin-top: 100px;

      }

      .drugie-spotkanie {
        color: orange;
        font-size: 24px;
      }

      .wiecej-tutaj {
        font-size: 14px;
      }


      .meeting-info-title1 {
        font-family: "Fingerpaint";
        font-size: 30px;
        grid-area: title-big;
        align-self: end;
        
        @include for-tablet-landscape-up {
          font-size: 34px;
        }

        @include for-desktop-up {
        font-size: 54px;
        }
      }

      .meeting-info-title2 {
        font-family: "Fingerpaint";
        font-size: 20px;
        grid-area: title-small;
        
        @include for-tablet-landscape-up {
          font-size: 20px;
  
        }

        @include for-desktop-up {
          font-size: 24px;
          }
      }

      .meeting-info-subtitle {
        font-family: "Garet";
        font-size: 20px;
        color: #ffb905;
        grid-area: subtitle;

        @include for-desktop-up {
          font-size: 24px;
          }
      }

      .meeting-info-subtitle2 {
        font-family: "Garet";
        font-size: 18px;
        color: black;
        font-weight: 600;

        grid-area: subtitle2;
        align-self: end;

        @include for-tablet-landscape-up {
          font-size: 18px;
  
        }
        @include for-desktop-up {
          font-size: 24px;
          }
      }
      .meeting-info-subtitle-diagonal {
        font-family: "Garet";
        font-size: 18px;
        color: black;
        font-weight: 600;
        margin-top: 30px;
        rotate: 26deg;

        grid-area: diagonal;
        align-self: end;

        @include for-tablet-landscape-up {
          font-size: 18px;
  
        }
        @include for-desktop-up {
          font-size: 16px;
          }
      }
      .meeting-info-text {
        font-size: 14px;
        font-family: "OpenSansLight";

        grid-area: rest;

        @include for-tablet-landscape-up {
          font-size: 14px;
  
        }
        @include for-desktop-up {
          font-size: 16px;
          }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .main-page-container {
  }
}

/******************/
/******************/
/******************/
/** GALLERY */
/******************/
/******************/
/******************/
/******************/

.main-page-container-place-photos {
  box-sizing: border-box;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  width: 100%;

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-desktop-up {
    grid-template-columns: repeat(3, 1fr);
  }

  /*
  flex-direction: row;
  align-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  */
  .image-gallery {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/******************/
/******************/
/******************/
/* QUESTIONS */
/******************/
/******************/
/******************/
/******************/

.main-page-container-las-main {
  background-image: url(https://www.ic.org/wp-content/uploads/2020/08/ev25_3776_82707-2-scaled-copy.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 60px;

  @include for-desktop-up {
  padding-top: 100px;
  padding-bottom: 100px;

  }
  p {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
    width: 90%;
    margin: 0 auto 50px;
    color: white;

    @include for-tablet-landscape-up {
      font-size: 24px;

    }
    @include for-desktop-up {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .main-page-container-las-main {
    background-image: url(https://www.ic.org/wp-content/uploads/2020/08/ev25_3776_82707-2-scaled-copy.jpg);

    background-repeat: no-repeat;
  }
}

/******************/
/******************/
/******************/
/** INFO */
/******************/
/******************/
/******************/
/******************/
.main-page-container-info {
  background-size: 100%;
  min-height: 50vw;
  background-repeat: no-repeat;
  @include for-tablet-landscape-up {

  padding-top: 60px;}

  .info-questions-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    font-size: 16px;
    font-family: "OpenSansLight";
gap: 40px;
    @include for-tablet-landscape-up {
      flex-direction: row;
    }
    @include for-desktop-up {
    font-size: 21px;

    }
    .info-questions {


      @include for-tablet-landscape-up {
        width: 46%;
      }
      p {
        margin-bottom: 0.9rem;
      }
    }
  }
}

/******************/
/******************/
/******************/
/* HELP US */
/******************/
/******************/
/******************/
/******************/

.main-page-container-join {
  padding-bottom: 90px;
  .picure-help-big {
    display: none;
    @include for-tablet-landscape-up{
    width: 800px;
    display: block;
    margin: auto;
    }
  }
  font-size: 21px;
  font-family: "OpenSansLight";
  @include for-big-desktop-up {
    width: 1800px;
    margin: 0 auto;
  }

  .join-box {
    width: 90%;
    font-size: 16px;
    margin: 0 auto;
    @include for-tablet-landscape-up {
      width: 800px;
      font-size: 20px;
    }
  }
}
