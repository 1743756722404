@import "./breakpoints.scss";

/********** ABOUT ******************/
/********** ABOUT ******************/
/********** ABOUT ******************/
/********** ABOUT ******************/
/********** ABOUT ******************/

.aboutus-container {
  width: 100%;

  .aboutus-box {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include for-tablet-landscape-up {
      padding-top: 70px;
    }

    @include for-desktop-up {
      padding: 50px;

    }

    .aboutus-title {
      font-size: 24px;
      font-weight: 600;
      color: #4196b8;
    }

    .aboutus-person-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 50px;

      @include for-tablet-landscape-up {
        flex-direction: row;

      }

      .aboutus-picture {
        height: 200px;
        max-width: 200px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto;

        @include for-desktop-up {
          height: 300px;
          max-width: 300px;
          margin: 0;
        }

        img {
          display: inline;
          margin: 0 auto;
          margin-left: -25%;
          height: 100%;
          width: auto;
        }

      }

      .aboutus-description {
        font-size: 16px;
        max-width: 500px;

        @include for-desktop-up {
          margin-left: 70px;
          max-width: 700px;
        }

      }
    }
  }
}