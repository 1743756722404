@import "./breakpoints.scss";


.info-container {
    width: 90%;
    background-color: white;
    border-radius: 16px;
    margin: 0 auto;


    @include for-tablet-landscape-up {
        width: 60%;

    }
    @include for-desktop-up {
        padding: 100px 0 
    }
}